function HomePage() {
  return (
    <div className={`
        h-screen
        w-screen
        flex
        justify-center
        items-center
        text-gray-400
        font-bold
        bg-gray-900
      `}
    >
      <div className='text-center text-2xl'>
          <h1 className="font-serif text-green-400 text-8xl">
          krystofyah 38
          </h1>
        <div className='text-md text-2xl font-sans font-semibold'>
          <p><strike>soon come</strike></p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
